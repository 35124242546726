<template>
  <div class="my-1">
    <span
      v-if="props.label"
      class="text-sm mb-1 text-black font-medium"
      v-text="props.label"
    />
    <div class="flex flex-col">
      <div v-for="val in props.values" :key="val" class="flex-grow pl-1">
        <label>
          <input
            :id="`radio_${val}`"
            v-model="childModel"
            type="radio"
            :value="val"
            :name="childName"
            class="cursor-pointer"
          />
        </label>
        <span class="pl-1 cursor-pointer" v-text="val" />
      </div>
    </div>
    <span
      v-if="props.helpText"
      class="text-sm text-gray-500 mt-1"
      v-text="props.helpText"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: string
    values: string[]
    label?: string
    helpText?: string
  }>(),
  {
    label: '',
    helpText: ''
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const childName = `radios_${Math.random()}`
const childModel = ref(props.modelValue)

watch(childModel, (value) => {
  emit('update:modelValue', value)
})
</script>
