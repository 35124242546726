import DocumentUpdateDialog from './DocumentUpdateDialog.vue'

import { type DatasheetBinding } from './rows/types'
import { createVueApp } from '../vue'

export function updateDialog(
  bindingData: DatasheetBinding,
  callback: (documentIds?: Array<string>, acceptChanges?: boolean) => void
) {
  const app = createVueApp(DocumentUpdateDialog, bindingData)

  const template = document.createElement('DIV')
  const instance = app.mount(template)

  instance.$.vnode.props = {
    onCallback: (...args: any[]) => {
      callback(...args)

      template.remove()
    }
  }

  document.body.appendChild(template)
}
