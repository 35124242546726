<template>
  <div class="flex flex-col">
    <label class="font-bold" v-text="header.name" />
    <input
      v-if="header.type === 'text'"
      v-model="value"
      type="text"
      class="avv-input-tw w-full"
    />
    <input
      v-else-if="header.type === 'number'"
      v-model="value"
      type="number"
      class="avv-input-tw w-full"
    />
    <textarea
      v-else-if="header.type === 'text_area'"
      v-model="value"
      class="avv-input-tw w-full"
    ></textarea>
    <DRadio
      v-else-if="header.type === 'radio'"
      v-model="value"
      :values="header.values"
      class="w-full"
    />
    <DDatePicker
      v-else-if="header.type === 'date'"
      v-model="value"
      :format="header.format"
      class="w-full"
    />
    <DDatasheetSelect
      v-else-if="header.type === 'select' || header.type === 'open_select'"
      v-model="value"
      :edit="edit"
      :values="header.values"
      :open="header.type === 'open_select'"
      class="w-full"
      :reference="!!header.reference"
      @insert="onInsert"
      @edit="onEdit"
    />
    <span
      v-if="header.required"
      class="text-sm text-gray-500"
      v-text="localize('required')"
    />
    <span
      v-for="message in errors"
      :key="message"
      class="text-sm text-red-500"
      v-text="message"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue'

import DRadio from './DRadio.vue'
import DDatePicker from './DDatePicker.vue'
import DDatasheetSelect from './DDatasheetSelect.vue'
import { isDatasheetSelectHeader, type DatasheetHeader } from './../types'

const props = defineProps<{
  value: string | null
  header: DatasheetHeader
  errors: string[]
  edit: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string, header: DatasheetHeader): void
  (e: 'insert', value: string, header: DatasheetHeader): void
  (e: 'edit', value: string, header: DatasheetHeader, id: string): void
}>()

const localize = (key: string): string =>
  window.localizeText(
    `datasheets.datasheet_record_dialog.datasheet_input.${key}`
  )

const value = ref(props.value ?? '')

watch(value, (value: string) => {
  emit('change', value, props.header)
})

const onInsert = (value: string) => {
  emit('insert', value, props.header)
}

const onEdit = (value: string, id: string) => {
  emit('edit', value, props.header, id)
}

const header = computed(() => props.header)
const edit = computed(
  () =>
    props.edit &&
    isDatasheetSelectHeader(props.header) &&
    !!props.header.reference
)
const errors = computed(() => props.errors ?? [])
</script>
