export type DatasheetHeaderReference = {
  id: string
  name: string
  datasheet_id: string
  dependencies: Array<{ header_id: string; reference_id: string }>
}

export type DatasheetHeaderBase = {
  id: string
  name: string
  required: boolean
  type: 'text' | 'number' | 'text_area'
  position: number
}

export type DatasheetDateHeader = {
  id: string
  name: string
  required: boolean
  type: 'date'
  format: string
  position: number
}

export type DatasheetRadioHeader = {
  id: string
  name: string
  required: boolean
  type: 'radio'
  format: string
  values: string[]
  position: number
}

export type DatasheetSelectHeader = {
  id: string
  name: string
  required: boolean
  type: 'select' | 'open_select'
  values: string[]
  reference?: DatasheetHeaderReference
  position: number
}

export const isDatasheetSelectHeader = (
  header: DatasheetHeader
): header is DatasheetSelectHeader =>
  header.type === 'select' || header.type === 'open_select'

export type DatasheetHeader =
  | DatasheetDateHeader
  | DatasheetRadioHeader
  | DatasheetSelectHeader
  | DatasheetHeaderBase

export type DatasheetBinding = {
  bindings: Array<[string, Array<[number, string]>]>
  values: Array<number>
}

export type DatasheetRowValues = {
  [key: string]: any
}

export type DatasheetRowTokens = DatasheetRowValues

export type DatasheetRow = {
  id: string
  values: DatasheetRowValues
  tokens: DatasheetRowTokens
  bindings: DatasheetBinding | undefined
}

export type DatasheetHeaders = {
  [key: string]: DatasheetHeader
}

export type DatasheetReturnData =
  | undefined
  | boolean
  | {
      [key: string]: string
    }

export type DatasheetErrors = {
  [key: string]: Array<string>
}

export type DatasheetEntry = {
  id: string
  name: string
  datasheet_row_id: any
  datasheet_row: DatasheetRowValues
  datasheet_rows: Array<DatasheetRow>
  datasheet_headers: DatasheetHeaders
  errors: DatasheetErrors
  returns: DatasheetReturnData
}

export type InsertActionResponse = {
  values: DatasheetRowValues
  status: boolean
  errors: DatasheetErrors
}

export type FindActionResponse = {
  id: string
  name: string
  datasheet_headers: DatasheetHeaders
  datasheet_rows: Array<DatasheetRow>
}
